import { CookieConsent } from './types';

export const LOCK_INTEGRATIONS_VERSION = true;
export const LOCK_PLUGINS_VERSION = true;

export const DEFAULT_COOKIE_CONSENT_CONFIG: Pick<
  CookieConsent,
  'isCookieConsentEnabled'
> = {
  isCookieConsentEnabled: false,
};
